<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $i18n.translate("Select Your Prize") }}</v-card-title>
      <v-card-text>
        <span v-if="isLoading" class="d-flex justify-center">
          <v-progress-circular :size="40" indeterminate color="primary" />
        </span>
        <v-alert v-else-if="!isWinner" type="error">
          {{
            $i18n.translate("You are not eligible to select a prize at this time or the page is accessed incorrectly.")
          }}
        </v-alert>
        <v-alert v-else-if="isSelected" type="success">
          {{ $i18n.translate("Award selection submitted. Please allow up to two(2) weeks for processing.") }}
        </v-alert>
        <v-data-table
          v-else-if="isWinner && products.length > 0"
          :headers="headers"
          :items="products"
          class="elevation-1"
          @pagination="onPageChange"
        >
          <template v-slot:item.retailPrice="{ item }">
            {{ `$${item.retailPrice}` }}
          </template>
          <template v-slot:item.imageUrl="{ item }">
            <v-img :src="getImageUrl(item.imageUrl)" height="100" max-width="100" contain />
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn @click="showDetails(item)">{{ $i18n.translate("Details") }}</v-btn>
          </template>
          <template v-slot:item.selection="{ item }">
            <v-radio-group v-model="selectedProduct" :mandatory="false">
              <v-radio :label="`${$i18n.translate('Select')} ${item.model}`" :value="item" />
            </v-radio-group>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn v-if="!isSelected" :disabled="isSubmittable" color="primary" @click="submitSelection">
          {{ $i18n.translate("Submit") }}
        </v-btn>
        <v-btn v-else color="primary" @click="goToDashboard">
          {{ $i18n.translate("Close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="detailsDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{ detailItem.name }}</v-card-title>
        <v-card-text>
          <div>
            <strong class="pr-2">{{ $i18n.translate("Model") }}:</strong>{{ detailItem.model }}
          </div>
          <div>
            <strong class="pr-2">{{ $i18n.translate("Description") }}:</strong>{{ detailItem.description }}
          </div>
          <div>
            <strong class="pr-2">{{ $i18n.translate("Retail Price") }}:</strong>$ {{ detailItem.retailPrice }}
          </div>
          <v-img v-if="detailItem.imageUrl" :src="getImageUrl(detailItem.imageUrl)" height="200" contain />
          <div>
            <strong>{{ $i18n.translate("Details") }}:</strong>
            <v-card class="pa-2">
              <p>{{ detailItem.details }}</p>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="detailsDialog = false">{{ $i18n.translate("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import priceOptions from "@/views/ccpr/rsa-incentive/price_options.json";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "SelectAward",
  metaInfo: {
    title: "Select Award"
  },
  data() {
    return {
      products: priceOptions,
      selectedProduct: null,
      isWinner: false,
      detailsDialog: false,
      isLoading: false,
      isSelected: false,
      detailItem: {
        name: "",
        model: "",
        description: "",
        retailPrice: "",
        imageUrl: "",
        details: ""
      },
      emailTemplateParticipantForm: {
        emailTemplateKey: "RSA_INCENTIVE_CONTEST_WINNER_SELECTED_PRIZE",
        context: {
          product: {
            id: ""
          }
        },
        bccParticipants: [{ email1: "amyd@gappgroup.com" }]
      },
      contestData: {},
      filters: {},
      headers: [
        { text: this.$i18n.translate("Category"), value: "category" },
        { text: this.$i18n.translate("Description"), value: "description" },
        { text: this.$i18n.translate("Retail Price"), value: "retailPrice" },
        { text: this.$i18n.translate("Model"), value: "model" },
        { text: this.$i18n.translate("Image"), value: "imageUrl", sortable: false },
        { text: this.$i18n.translate("Details"), value: "details", sortable: false },
        { text: this.$i18n.translate("Select"), value: "selection", sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isSubmittable() {
      return !this.selectedProduct || this.isLoading;
    }
  },
  created() {
    this.checkWinnerStatus();
  },
  methods: {
    getImageUrl(imageKey) {
      return require(`@/assets/rsa-incentive/${imageKey}`);
    },
    async checkWinnerStatus() {
      this.isLoading = true;
      try {
        const promotionResponse = await this.$api.get("/api/promotions/" + this.$route.params.id);
        const promotionData = promotionResponse.data;
        const contestResponse = await this.$api.getRelatedObject("contest", promotionData);
        this.contestData = contestResponse.data;
        this.filters.hasSelectedDate = true;
        this.filters.contest = { id: this.contestData.id };
        this.filters.participant = { id: this.selectedParticipant.id };
        const entriesResponse = await this.$api.post("/api/contestEntries/search", this.filters);
        const entriesData = entriesResponse.data;
        if (entriesData.content.some(entry => entry.selectedPrize)) {
          this.isSelected = true;
          return;
        }
        if (entriesData && !entriesData.empty) {
          this.isWinner = true;
        } else {
          console.error("No Contest entries found.");
        }
      } catch (error) {
        console.error("An error occurred while checking winner status:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitSelection() {
      this.isLoading = true;
      try {
        if (!this.selectedProduct) {
          this.$router.push({ name: "dashboard" });
        }
        this.emailTemplateParticipantForm.participant = { id: this.selectedParticipant.id };
        this.emailTemplateParticipantForm.context.product = this.selectedProduct;
        this.emailTemplateParticipantForm.bccParticipants.push({ email1: this.selectedProgram.programEmail });
        let postForm = {};
        postForm.selectedPrize = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        postForm.emailTemplateParticipantForm = this.emailTemplateParticipantForm;
        await this.$api.post(
          `/api/contests/selectedPrize/${this.contestData.id}/${this.selectedParticipant.id}`,
          postForm
        );
        this.isSelected = true;
      } catch (error) {
        console.error("Failed to submit the selected product:", error);
      } finally {
        this.isLoading = false;
      }
    },
    showDetails(item) {
      this.detailItem = item;
      this.detailsDialog = true;
    },
    onPageChange() {
      this.selectedProduct = null;
    },
    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>
