var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$i18n.translate("Select Your Prize")))]),_c('v-card-text',[(_vm.isLoading)?_c('span',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":40,"indeterminate":"","color":"primary"}})],1):(!_vm.isWinner)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("You are not eligible to select a prize at this time or the page is accessed incorrectly."))+" ")]):(_vm.isSelected)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Award selection submitted. Please allow up to two(2) weeks for processing."))+" ")]):(_vm.isWinner && _vm.products.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products},on:{"pagination":_vm.onPageChange},scopedSlots:_vm._u([{key:"item.retailPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.retailPrice)))+" ")]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.getImageUrl(item.imageUrl),"height":"100","max-width":"100","contain":""}})]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(_vm.$i18n.translate("Details")))])]}},{key:"item.selection",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{attrs:{"mandatory":false},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}},[_c('v-radio',{attrs:{"label":((_vm.$i18n.translate('Select')) + " " + (item.model)),"value":item}})],1)]}}])}):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end"},[(!_vm.isSelected)?_c('v-btn',{attrs:{"disabled":_vm.isSubmittable,"color":"primary"},on:{"click":_vm.submitSelection}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Submit"))+" ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToDashboard}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Close"))+" ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.detailItem.name))]),_c('v-card-text',[_c('div',[_c('strong',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$i18n.translate("Model"))+":")]),_vm._v(_vm._s(_vm.detailItem.model)+" ")]),_c('div',[_c('strong',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$i18n.translate("Description"))+":")]),_vm._v(_vm._s(_vm.detailItem.description)+" ")]),_c('div',[_c('strong',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$i18n.translate("Retail Price"))+":")]),_vm._v("$ "+_vm._s(_vm.detailItem.retailPrice)+" ")]),(_vm.detailItem.imageUrl)?_c('v-img',{attrs:{"src":_vm.getImageUrl(_vm.detailItem.imageUrl),"height":"200","contain":""}}):_vm._e(),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$i18n.translate("Details"))+":")]),_c('v-card',{staticClass:"pa-2"},[_c('p',[_vm._v(_vm._s(_vm.detailItem.details))])])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.detailsDialog = false}}},[_vm._v(_vm._s(_vm.$i18n.translate("Close")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }